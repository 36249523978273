import { Controller } from '@hotwired/stimulus';
import { SynoptiqueGridHandler } from '../js/synoptique_grid_handler';
import { EDITOR_OBJECTS } from '../js/synoptique-elements-config';
import { getClosestTracksIntersection, arePointsIdentical } from '../js/grid-2d-engine';
import { parseSynoptique, parseSynoptiqueSensors, drawSynoptique, computeSynoptiqueOpenRoutes, computeTipConnections, saveTracks } from '../js/synoptique-elements-handler';
import 'jquery-timepicker/jquery.timepicker';

export default class extends Controller {
    viewer;
    plumber;
    gridHandler;
    elementVisualiser;
    visualizedElement;
    visualizedElementConfig;
    synoptique;
    sensorIdValues;
    itemsToDelete = {
        construction_sections: [],
    };

    static values = {
        synoptique: Object
    }

    connect() {        
        this.viewer = $(this.element).find('.viewer');
        this.elementVisualiser = $(this.element).find('.visualisation-element');
        this.elementVisualiser.find('#close').on('click', () => {
            $(this.element).find('.visualized').removeClass('visualized');
            this.visualizedElement = null;
            this.elementVisualiser.hide()
        });
        this.synoptique = parseSynoptique(this.synoptiqueValue);
        this.sensorIdValues = parseSynoptiqueSensors(this.synoptiqueValue);
        this.gridHandler = new SynoptiqueGridHandler(this.synoptique, this.viewer, (tag, obj) => this.attachEvents(tag, obj), (element) => {
            saveTracks(this.synoptique);
            this.drawSynoptique();
        });
        this.drawSynoptique();
        this.initDatePicker();
        this.initInterfaceActions();
    }

    attachEvents(tag, obj) {
        Object.values(EDITOR_OBJECTS).forEach((objConfig) => {
            tag.find(Array.isArray(objConfig.classTagToClick) ? ('.' + objConfig.classTagToClick.join(', .')) :  '.' + objConfig.classTagToClick)
              .addBack('.' + objConfig.classTagToClick).on('dblclick', () => {
                $(this.element).find('.visualized').removeClass('visualized');
                tag.addClass('visualized');
                this.visualizedElement = obj;
                this.visualizedElementConfig = objConfig;
                this.elementVisualiser.show();
                this.elementVisualiser.find('.element-type').text(objConfig.label);
                this.drawElementInfos(obj, objConfig);
            });
        });

        return tag;
    }

    fetchSensorsData(date) {
        $('.sensor-fetch-waiting-loader').show(); $('.btn-reset-datetime-picker').hide();
        $.get(window.location.href.split('?')[0] + '/sensors/data?apiKey=' + process.env.SENSORS_QUERY_API_KEY + (date ? '&date=' + date : ''))
            .then((res) => {
                [this.sensorIdValues.derailers, this.sensorIdValues.instrumentalizedSectionsStart, this.sensorIdValues.instrumentalizedSectionsEnd, this.sensorIdValues.switchesBranchesLeft, this.sensorIdValues.switchesBranchesRight].forEach((list) => Object.values(list).forEach((s) => {
                    s.value = null;
                    s.date = null;
                }));
                res.forEach((sensor) => {
                    switch (sensor.type) {
                        case 'derailer':
                            this.sensorIdValues.derailers[sensor.sensorId] = {
                                type: sensor.type,
                                value: sensor.value,
                                date: sensor.date
                            };
                            break;
                        case 'section-start':
                            this.sensorIdValues.instrumentalizedSectionsStart[sensor.sensorId] = {
                                type: sensor.type,
                                value: sensor.value,
                                date: sensor.date
                            };
                            break;
                        case 'section-end':
                            this.sensorIdValues.instrumentalizedSectionsEnd[sensor.sensorId] = {
                                type: sensor.type,
                                value: sensor.value,
                                date: sensor.date
                            };
                            break;
                        case 'switch-branch-left':
                            this.sensorIdValues.switchesBranchesLeft[sensor.sensorId] = {
                                type: sensor.type,
                                value: sensor.value,
                                date: sensor.date
                            };
                            break;
                        case 'switch-branch-right':
                            this.sensorIdValues.switchesBranchesRight[sensor.sensorId] = {
                                type: sensor.type,
                                value: sensor.value,
                                date: sensor.date
                            };
                            break;
                    }
                });

                // Update last measures dates in synoptique object and other data for forms
                Object.entries(this.sensorIdValues.derailers).forEach((s) => {
                    for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                        var elementIndex = this.synoptique.tracks[iTrack].derailers.map(d => d.data.sensorId).indexOf(s[0]);
                        if(elementIndex >= 0) {
                            this.synoptique.tracks[iTrack].derailers[elementIndex].data.lastMeasureDate = s[1].date;
                        }
                    }
                })
                Object.entries(this.sensorIdValues.instrumentalizedSectionsStart).forEach((s) => {
                    for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                        var elementStartIndex = this.synoptique.tracks[iTrack].instrumentalizedSections.map(d => d.data.sensorStartId).indexOf(s[0]);
                        if(elementStartIndex >= 0) {
                            this.synoptique.tracks[iTrack].instrumentalizedSections[elementStartIndex].data.axleCountAtStart = s[1].value;
                            this.synoptique.tracks[iTrack].instrumentalizedSections[elementStartIndex].data.lastMeasureSensorStartDate = s[1].date;
                        }
                    }
                })
                Object.entries(this.sensorIdValues.instrumentalizedSectionsEnd).forEach((s) => {
                    for (var iTrack = 0; iTrack < this.synoptique.tracks.length; iTrack++) {
                        var elementEndIndex = this.synoptique.tracks[iTrack].instrumentalizedSections.map(d => d.data.sensorEndId ?? d.data.sensorStartId).indexOf(s[0]);
                        if(elementEndIndex >= 0) {
                            this.synoptique.tracks[iTrack].instrumentalizedSections[elementEndIndex].data.axleCountAtEnd = s[1].value;
                            this.synoptique.tracks[iTrack].instrumentalizedSections[elementEndIndex].data.lastMeasureSensorEndDate = s[1].date;
                        }
                    }
                })

                Object.entries(this.sensorIdValues.switchesBranchesLeft).forEach((s) => {
                    var elementIndex = this.synoptique.switches.map(d => d.data.sensorAId).indexOf(s[0]);
                    if(elementIndex >= 0) {
                        this.synoptique.switches[elementIndex].data.sensorAValue = s[1].value
                        this.synoptique.switches[elementIndex].data.lastMeasureDateSensorA = s[1].date;
                        if(!this.synoptique.switches[elementIndex].data.sensorBEnabled) {
                            this.synoptique.switches[elementIndex].data.lastMeasureDateSensorB = s[1].date;
                            this.synoptique.switches[elementIndex].data.sensorBValue = s[1].value !== null ? !s[1].value : null
                        }
                    }
                })
                Object.entries(this.sensorIdValues.switchesBranchesRight).forEach((s) => {
                    var elementIndex = this.synoptique.switches.map(d => d.data.sensorBId).indexOf(s[0]);
                    if(elementIndex >= 0 && this.synoptique.switches[elementIndex].data.sensorBEnabled) {
                        this.synoptique.switches[elementIndex].data.sensorBValue = s[1].value
                        this.synoptique.switches[elementIndex].data.lastMeasureDateSensorB = s[1].date;
                    }
                })

                this.drawSynoptique();
            }).always(() => {
                $('.sensor-fetch-waiting-loader').hide();
                $('.btn-reset-datetime-picker').show();

                setTimeout(() => {
                    let dateValue = $('.visualisation-datepicker input[type="date"]').val();
                    let timeValue = $('.visualisation-datepicker input[type="text"]').val();
                    if(!dateValue || !timeValue) this.fetchSensorsData(encodeURI(new Date().toISOString()));
                }, 3000);
            });
    }

    updateSensors() {
        Object.entries(this.sensorIdValues.switchesBranchesLeft).concat(Object.entries(this.sensorIdValues.switchesBranchesRight)).forEach((entry) => {
            let tag = entry[1].type == 'switch-branch-left' ? $('.track-switch[data-sensor-a-id="' + entry[0] + '"]') : $('.track-switch[data-sensor-b-id="' + entry[0] + '"]');
            
            if(!tag.length) return;
            tag.removeClass('state-unknown');
            tag.removeClass('desync');
            tag.removeClass('plaque-a');
            tag.removeClass('plaque-b');
            var val = entry[1].value !== null || entry[1].value === undefined ? entry[1].value === true || entry[1].value === (entry[1].type == 'switch-branch-left' ? 'plated' : 'non-plated') : null;
            if(val === null)
                tag.addClass(entry[1].date ? 'state-unknown' : 'desync');
            else {
                if(val) tag.addClass(entry[1].type == 'switch-branch-left' ? 'plaque-a' : 'plaque-b');
                else tag.addClass(entry[1].type == 'switch-branch-left' ? 'plaque-b' : 'plaque-a');
            }
        });
        $('.track-section-counter-value').text('0')
        $('.track-section').removeClass('occupied')
        Object.entries(this.sensorIdValues.instrumentalizedSectionsStart).forEach((entry) => {
            $('.track-section[data-sensor-start-id="' + entry[0] + '"]').each((i, tag) => {
                if(!$(tag).length || entry[1].value === null || entry[1].value === undefined) return;
                else {
                    var endValue = this.sensorIdValues.instrumentalizedSectionsEnd[$(tag).data('sensor-end-id') ? $(tag).data('sensor-end-id') : entry[0]]?.value;
                    if(entry[1].value != endValue) $(tag).addClass('occupied');
                    $(tag).find('.track-section-counter-value').text(Math.abs(entry[1].value - endValue))
                }
            });
        })
        Object.entries(this.sensorIdValues.derailers).forEach((entry) => {
            let tag = $('.track-derailer[data-sensor-id="' + entry[0] + '"]');
            this.synoptique.tracks.forEach((t) => t.derailers.filter((d) => d.data.sensorId == entry[0]).forEach((d) => d.data.currentState = entry[1].value));
            if(!tag.length || entry[1].value === null || entry[1].value === undefined) return;
            if(entry[1].value) tag.removeClass('state-down');
            else tag.addClass('state-down');
        })

        $('.kpi-vigilance .kpi-value').text($('.track-switch.desync').length)
        $('.kpi-danger .kpi-value').text($('.track-switch.state-unknown').length)
    }

    drawSynoptique() {
        let routesToHighlight = computeSynoptiqueOpenRoutes(this.synoptique);
        computeTipConnections(this.synoptique.tips, routesToHighlight);
        var routesHighlighting = routesToHighlight.map((routePath) => {
            var doesPointIntersectWithOtherTracks = getClosestTracksIntersection(routePath[routePath.length - 1], this.synoptique.tracks, { maximumDistance: 0.1 }) != null;
            var isRouteComplete = !doesPointIntersectWithOtherTracks && this.synoptique.tracks.some((t) => {
                var isTrackStartRouteTerminus = arePointsIdentical(t.data.path[0], routePath[routePath.length - 1]); 
                var isTrackEndRouteTerminus = arePointsIdentical(t.data.path[t.data.path.length - 1], routePath[routePath.length - 1]);
                return isTrackStartRouteTerminus || isTrackEndRouteTerminus;
            });
            var lineTag = $(`<div class="track free-route ${isRouteComplete ? 'complete-route' : ''}"><svg class="track-line"><polyline/></svg></div>`);
            this.gridHandler.grid.connectElements(lineTag.find('.track-line'), routePath);
            return lineTag;
            //look for tip subroutes
        });
        drawSynoptique(this.synoptique, this.viewer, this.gridHandler.grid, (tag, obj) => this.attachEvents(tag, obj));
        $('.track-switch').addClass('desync');
        this.updateSensors();
        $('.free-route').remove();
        routesHighlighting.forEach((lineTag) => this.viewer.append(lineTag));
        // Redraw element infos to update last measures dates
        if(this.visualizedElement)
            this.drawElementInfos(this.visualizedElement, this.visualizedElementConfig);
    }

    drawElementInfos(obj, objConfig) {
        var dataObj = obj.data;
        var detailForm = objConfig.detailInfos;
        $(this.element).find('#elementInfos').empty();
        $(this.element).find('#elementInfos').append(Object.keys(detailForm ?? {}).map(field => {
            switch (detailForm[field].type) {
                case 'text':
                case 'number':
                    return $(`<div class="info-label">${detailForm[field].label}</div><div class="info-value">${dataObj[field] ?? detailForm[field].placeholder ?? ''}</div>`)
                case 'select':
                    return $(`<div class="info-label">${detailForm[field].label}</div><div class="info-value">${detailForm[field].choices[dataObj[field]] ?? detailForm[field].placeholder ?? ''}</div>`)
                case 'checkbox':
                    return $(`<div class="formbuilder-checkbox">
                        <label class="info-label" for="field-${field}">${detailForm[field].label}</label>
                        <input class="checkbox info-value" id="field-${field}" disabled="disabled" value="value" type="checkbox" ${dataObj[field] ? 'checked="checked"' : ''}">
                    </div>`);
                case 'date':
                    var formatter = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long' });
                    return $(`<div class="info-label">${detailForm[field].label}</div><div class="info-value">${dataObj[field] ? formatter.format(Date.parse(dataObj[field])) : 'N/A'}</div>`)
                case 'datetime':
                    var formatter = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long', timeStyle: 'short' });
                    return $(`<div class="info-label">${detailForm[field].label}</div><div class="info-value">${dataObj[field] ? formatter.format(Date.parse(dataObj[field])) : 'N/A'}</div>`)
            }
        }));
        $(this.element).find('.element-action-bar').empty();
        objConfig.detailViewActions?.forEach((viewAction) => {
            var btn = $(`<button type="button" class="btn ${viewAction.class}">${viewAction.label}</button>`);
            btn.on('click', () => {
                viewAction.action.apply(this, [obj, $($('.visualized').first())]);
                if(viewAction.redrawAfter) {
                    this.drawSynoptique();
                    this.elementVisualiser.hide();
                }
            })
            $(this.element).find('.element-action-bar').append(btn);
        });
    }

    initDatePicker() {
        $('.visualisation-datepicker input[type="date"]').on('change', (e) => {
            if(!$(e.target).val()) {
                $('.visualisation-datepicker').removeClass('manual');
            } else {
                $('.visualisation-datepicker').addClass('manual');
            }
            let dateValue = $(e.target).val();
            let timeValue = $('.visualisation-datepicker input[type="text"]').val();
            $('.visualisation-datepicker .date-value').text(dateValue ? dateValue.split('-').reverse().join('/') : 'Temps Réel');
            $('.visualisation-datepicker .time-value').text(timeValue ? timeValue : '0:00');
            if(dateValue && timeValue) this.fetchSensorsData(encodeURI(new Date(dateValue + 'T' + timeValue.padStart(5, '0') + ':00').toISOString()));
        })
        $('.visualisation-datepicker input[type="text"]').on('time-change', (e) => {
            $('.visualisation-datepicker .time-value').text($(e.target).val() ? $(e.target).val() : '0:00');
            let dateValue = $('.visualisation-datepicker input[type="date"]').val();
            let timeValue = $(e.target).val();
            if(dateValue && timeValue) this.fetchSensorsData(encodeURI(new Date(dateValue + 'T' + timeValue.padStart(5, '0') + ':00').toISOString()));
        })
        $('.btn-reset-datetime-picker').on('click', () => {
            let datePicker = $('.visualisation-datepicker input[type="date"]');
            let timePicker = $('.visualisation-datepicker input[type="text"]');
            datePicker.val(''); timePicker.val('');
            datePicker.trigger('change'); timePicker.trigger('change');
            this.fetchSensorsData(encodeURI(new Date().toISOString()));
        })

        $('.visualisation-datepicker .timepicker').timepicker({
            timeFormat: 'H:mm',
            interval: 15,
            minTime: '0',
            maxTime: '23:45',
            defaultTime: '12',
            startTime: '00:00',
            dynamic: false,
            dropdown: true,
            scrollbar: true
        })

        var dateQueryParam = new URLSearchParams(window.location.search).get('date');
        var initialDate = new Date(dateQueryParam);
        if(dateQueryParam && initialDate && new Date(initialDate) != new Date('')) {
            $('.visualisation-datepicker input[type="text"]').val(initialDate.getHours().toString() + ':' + initialDate.getMinutes().toString().padStart(2, '0'));
            $('.visualisation-datepicker input[type="text"]').trigger('time-change');
            $('.visualisation-datepicker input[type="date"]').val(initialDate.getFullYear() + '-' + (initialDate.getMonth() + 1).toString().padStart(2, '0') + '-' + initialDate.getDate().toString().padStart(2, '0'));
            $('.visualisation-datepicker .date-value').text($('.visualisation-datepicker input[type="date"]').val().split('-').reverse().join('/'));
        }

        let dateValue = $('.visualisation-datepicker input[type="date"]').val();
        let timeValue = $('.visualisation-datepicker input[type="text"]').val();
        if(!dateValue || !timeValue) this.fetchSensorsData(encodeURI(new Date().toISOString()));
    }

    initInterfaceActions() {
        $('.menu-action').on('click', (e) => {
            let actionName = $(e.target).closest('.menu-action').data('action');
            this.gridHandler.initCreateAction(actionName);
        });

        this.viewer.on('resize', () => this.drawSynoptique());
    }
}