import { getPathIntersections, getPathLength, getTrackSubPath, arePointsIdentical, isPointOnPath } from '../js/grid-2d-engine';
import { EDITOR_OBJECTS } from '../js/synoptique-elements-config';

function parseSynoptique(json) {
    return {
        id: json.id,
        title: json.title,
        clientId: json.clientId,
        public: json.public,
        tracks: json.tracks?.map(track => {
            return {
                id: track.id,
                data: track,
                startingEdge: track.startingEdge ? {
                    id: track.startingEdge.id,
                    data: {
                        label: track.startingEdge.label,
                        labelPosition: { x: track.startingEdge.xLabelPosition, y: track.startingEdge.yLabelPosition },
                    },
                } : null,
                closingEdge: track.closingEdge ? {
                    id: track.closingEdge.id,
                    data: {
                        label: track.closingEdge.label,
                        labelPosition: { x: track.closingEdge.xLabelPosition, y: track.closingEdge.yLabelPosition }
                    },
                } : null,
                instrumentalizedSections: track.instrumentalizedSections?.map(section => {
                    return {
                        id: section.id,
                        data: {
                            positionStart: { x: section.xPositionStart, y: section.yPositionStart },
                            positionEnd: { x: section.xPositionEnd, y: section.yPositionEnd },
                            sensorStartId: section.sensorStartId,
                            sensorEndId: section.sensorEndId,
                            axleCountAtStart: section.axleCountAtStart,
                            axleCountAtEnd: section.axleCountAtEnd,
                            lastMeasureSensorStartDate: section.lastMeasureSensorStartDate,
                            lastMeasureSensorEndDate: section.lastMeasureSensorEndDate,
                            dualSensor: section.dualSensor,
                        },
                    }
                }) ?? [],
                constructionSections: track.constructionSections?.map(section => {
                    return {
                        id: section.id,
                        data: {
                            positionStart: { x: section.xPositionStart, y: section.yPositionStart },
                            positionEnd: { x: section.xPositionEnd, y: section.yPositionEnd }
                        },
                    }
                }) ?? [],
                derailers: track.derailers?.map(derailer => {
                    return {
                        id: derailer.id,
                        data: {
                            position: { x: derailer.xPosition, y: derailer.yPosition },
                            trackId: track.id,
                            sensorId: derailer.sensorId,
                            currentState: derailer.currentState,
                            lastMeasureDate: derailer.lastMeasureDate,
                        },
                    }
                }) ?? [],
            }
        }) ?? [],
        switches: json.switches?.map(swt => {
            return {
                id: swt.id,
                data: {
                    number: swt.number,
                    intersection: { x: swt.xIntersection, y: swt.yIntersection },
                    sensorAId: swt.branchingTracks[0]?.sensorId,
                    sensorBId: swt.branchingTracks[1]?.sensorId,
                    sensorAValue: swt.branchingTracks[0]?.plaque,
                    sensorBValue: swt.sensorBEnabled ? swt.branchingTracks[1]?.plaque : swt.branchingTracks[0]?.plaque === undefined || swt.branchingTracks[0]?.plaque === null ? null : !swt.branchingTracks[0].plaque,
                    lastMeasureDateSensorA: swt.branchingTracks[0]?.lastMeasureDate,
                    lastMeasureDateSensorB: swt.sensorBEnabled ? swt.branchingTracks[1]?.lastMeasureDate : swt.branchingTracks[1]?.lastMeasureDate,
                    sensorBEnabled: swt.sensorBEnabled,
                    branchingTracks: swt.branchingTracks?.map(branch => {
                        return {
                            id: branch.id,
                            position: { x: branch.xPosition, y: branch.yPosition },
                            intersection: { x: swt.xIntersection, y: swt.yIntersection },
                            trackId: branch.track.id
                        };
                    })
                }
            }
        }) ?? [],
        notes: json.notes?.map(note => {
            return {
                id: note.id,
                data: {
                    position: { x: note.xPosition, y: note.yPosition },
                    content: note.content,
                    backgroundColor: note.backgroundColor,
                    backgroundOpacity: note.backgroundOpacity,
                    fontColor: note.fontColor,
                    fontSize: note.fontSize
                },
            }
        }) ?? [],
        tips: json.tips?.map(tip => {
            let node = {
                id: tip.id,
                data: {
                    position: { x: tip.xPosition, y: tip.yPosition },
                    label: tip.label
                },
            }
            node.data.departurePoints = tip.departurePoints.map(tipPoint => {
                return { position: { x: tipPoint.xPosition, y: tipPoint.yPosition }, label: tipPoint.label, tip: node }
             });
            node.data.arrivalPoints = tip.arrivalPoints.map(tipPoint => {
                return { position: { x: tipPoint.xPosition, y: tipPoint.yPosition }, label: tipPoint.label, tip: node }
            });
            return node;
        }) ?? []
    };
}

function parseSynoptiqueSensors(json) {
    let sensorValues = {
        'switchesBranchesLeft': {},
        'switchesBranchesRight': {},
        'derailers': {},
        'instrumentalizedSectionsStart': {},
        'instrumentalizedSectionsEnd': {}
    };
    json.tracks?.forEach(track => {
        track.instrumentalizedSections?.forEach(section => {
            if(section.sensorStartId)
                sensorValues.instrumentalizedSectionsStart[section.sensorStartId] = {
                    value: section.axleCountAtStart,
                    date: section.lastMeasureSensorStartDate
                };
            if(section.dualSensor && section.sensorEndId)
                sensorValues.instrumentalizedSectionsEnd[section.sensorEndId] = {
                    value: section.axleCountAtEnd,
                    date: section.lastMeasureSensorEndDate
                };
            if(!section.dualSensor && section.sensorStartId)
                sensorValues.instrumentalizedSectionsEnd[section.sensorStartId] = {
                    value: section.axleCountAtEnd,
                    date: section.lastMeasureSensorEndDate
                };
        });
        track.derailers?.forEach(derailer => {
            if(derailer.sensorId)
                sensorValues.derailers[derailer.sensorId] = {
                    value: derailer.currentState,
                    date: derailer.lastMeasureDate
                };
        });
    });
    json.switches?.forEach((swt) => {
        if(swt.branchingTracks[0]?.sensorId)
            sensorValues.switchesBranchesLeft[swt.branchingTracks[0]?.sensorId] = {
                type: 'switch-branch-left',
                value: swt.branchingTracks[0]?.plaque,
                date: swt.branchingTracks[0]?.lastMeasureDate
            };
        if(swt.sensorBEnabled && swt.branchingTracks[1]?.sensorId)
            sensorValues.switchesBranchesRight[swt.branchingTracks[1]?.sensorId] = {
                type: 'switch-branch-right',
                value: swt.branchingTracks[1]?.plaque,
                date: swt.branchingTracks[1]?.lastMeasureDate
            };
    });

    return sensorValues;
}

function drawSynoptique(synoptique, container, grid, eventsAttacher) {
    container.empty();
    synoptique.tracks.forEach((track) => {
        container.append(eventsAttacher(EDITOR_OBJECTS.track.getTag(track, grid), track));
        if(track.startingEdge) container.append(eventsAttacher(EDITOR_OBJECTS.edge.getTag(track.startingEdge, grid, track), track.startingEdge));
        if(track.closingEdge) container.append(eventsAttacher(EDITOR_OBJECTS.edge.getTag(track.closingEdge, grid, track), track.closingEdge));
        track.constructionSections.forEach((node) => container.append(eventsAttacher(EDITOR_OBJECTS.construction_section.getTag(node, grid, track), node)));
        track.instrumentalizedSections.forEach((node) => container.append(eventsAttacher(EDITOR_OBJECTS.section.getTag(node, grid, track), node)));
        track.derailers.forEach((node) => container.append(eventsAttacher(EDITOR_OBJECTS.derailer.getTag(node, grid, track), node)));
    });
    synoptique.switches.forEach((node) => container.append(eventsAttacher(EDITOR_OBJECTS.switch.getTag(node, grid), node)));
    synoptique.notes.forEach((node) => container.append(eventsAttacher(EDITOR_OBJECTS.note.getTag(node, grid), node)));
    synoptique.tips.forEach((node) => container.append(eventsAttacher(EDITOR_OBJECTS.tip.getTag(node, grid), node)));
}

function getBlockingSwitchesBranches(switches, track) {
    return [].concat(...switches
        .map((swt) => swt.data.branchingTracks.filter((bt, i) => {
            return bt.trackId == track.id
            && (i == 0 ? swt.data.sensorAValue : (swt.data.sensorBEnabled ? swt.data.sensorBValue : swt.data.sensorAValue === false)) !== true
        }))
        .filter((bts) => bts.length > 0)
    );
}

function isPointOnSwitch(point, switches) {
    return switches.some((swt) => arePointsIdentical(swt.data.intersection, point));
}

function getBlockingDerailers(derailers) {
    return derailers.filter((d) => d.data.currentState !== 0);
}

function computeSynoptiqueTipRoutes(synoptique) {
    let connectedTIPs = [];
    synoptique.tips.forEach((tip) => {
        tip.data.departurePoints.forEach((dp) => {
            var track = synoptique.tracks.find((t) => isPointOnPath(dp.position, t.data.path));
            var openRoutes = computeOpenRoutesFromTrackPoint(dp.position, track, synoptique, []);
            openRoutes.forEach((routePath) => {
                var ap = tip.data.arrivalPoints.find((ap) => isPointOnPath(ap.position, routePath));
                if(ap) {
                    tip.data.connection = { departure: dp, arrival: ap };
                    connectedTIPs.push({ tipId: tip.id, connection: { departure: dp.label, arrival: ap.label } });
                }
            })
        })
    });
    // On retire les doublons et on passe par le JSON pour ça (limitation du JS en termes d'opérateur de comparaison)
    return connectedTIPs
        .map(connection => JSON.stringify(connection))
        .filter((value, index, array) => array.indexOf(value) === index)
        .map(json => JSON.parse(json));
}

function computeSynoptiqueOpenRoutes(synoptique) {
    let openRoutes = [];
    synoptique.tracks.forEach((t) => {
        var exploredPoints = [];
        if(t.data.routeFromStartEnabled) openRoutes.push(...computeOpenRoutesFromTrackPoint(t.data.path[0], t, synoptique, exploredPoints));
        if(t.data.routeFromEndEnabled) openRoutes.push(...computeOpenRoutesFromTrackPoint(t.data.path[t.data.path.length - 1], t, synoptique, exploredPoints))
    });
    // On retire les doublons et on passe par le JSON pour ça (limitation du JS en termes d'opérateur de comparaison)
    return openRoutes
        .map(route => JSON.stringify(route.map((p) => { return { x: p.x, y: p.y } })))
        .filter((value, index, array) => array.indexOf(value) === index)
        .map(json => JSON.parse(json));
}

function computeTipConnections(tips, openRoutes) {
    let connectedTIPs = [];
    for(var tip of tips) {
        tip.data.connection = null;
        tipLoop: {
            for(var routePath of openRoutes) {
                for(var dp of tip.data.departurePoints) {
                    if(isPointOnPath(dp.position, routePath)) {
                        for(var ap of tip.data.arrivalPoints) {
                            if(isPointOnPath(ap.position, routePath)) {
                                tip.data.connection = { departure: dp, arrival: ap };
                                connectedTIPs.push({ tipId: tip.id, connection: { departure: dp.label, arrival: ap.label } })
                                break tipLoop;
                            }
                        }
                    }
                }
            }
        }
    }
    return connectedTIPs;
}

function exploreRoutesOnPath(origin, path, synoptique, currentTrack, blockingElementPosition, forbiddenOrigins) {
    let result = [];
    if(getPathLength(path) > 0) {
        // Si le premier point n'est pas l'origine c'est qu'il s'agit du dernier point et il faut donc inverser le path
        if(path[0].x != origin.x || path[0].y != origin.y) path.reverse();
        let pathIntersections = getPathIntersections(synoptique.tracks.filter(t2 => currentTrack != t2), path)
        // On ne prends pas les intersetions qui sont au point de départ (on en vient, donc les chemins irradiant depuis ce point ont déjà été explorés)
        .filter((pi) => pi.point.x != origin.x || pi.point.y != origin.y);

        // On ne veut pas s'engager dans une nouvelle voie si c'est uniquement pour s'arrêter sur un point bloquant
        let isPointOnBlockingElement = blockingElementPosition && (arePointsIdentical(path[0], blockingElementPosition) || arePointsIdentical(path[path.length - 1], blockingElementPosition));
        if(!isPointOnBlockingElement || pathIntersections.length > 0) result.push(path);
        // Pour ce chemin on veut explorer les intersections avec d'autres voies
        pathIntersections.forEach((intersection) => result.push(
            ...computeOpenRoutesFromTrackPoint(intersection.point, intersection.track, synoptique, forbiddenOrigins)
                .map((subPath) => {
                    var pathBeforeIntersection = getTrackSubPath(currentTrack, origin, intersection.point);
                    // Si le premier point n'est pas l'origine c'est qu'il s'agit du dernier point et il faut donc inverser le path
                    if(pathBeforeIntersection[0].x != origin.x || pathBeforeIntersection[0].y != origin.y) pathBeforeIntersection.reverse();
                    pathBeforeIntersection.pop();
                    return pathBeforeIntersection.concat(...subPath);
                })
        ));
    }
    return result;
}

function computeOpenRoutesFromTrackPoint(point, track, synoptique, forbiddenOrigins) {
    let result = [];

    if(forbiddenOrigins.filter((p) => p.track.id === track.id && arePointsIdentical(p.point, point)).length > 0)
        return result;

    forbiddenOrigins.push({ track, point });

    // On recherche les switchs qui sont sur la voie
    var blockingElementsPositions = [].concat(
        ...getBlockingSwitchesBranches(synoptique.switches, track).map((branch) => branch.position),
        ...getBlockingDerailers(track.derailers).map((branch) => branch.data.position),
        ...track.constructionSections.map((section) => section.data.positionStart),
        ...track.constructionSections.map((section) => section.data.positionEnd)
    );
    var pathsToBlockingElements = blockingElementsPositions.map((pos) => { return { target: point, path: getTrackSubPath(track, point, pos)} });

    let trackPathToElements = {
        startingSide: pathsToBlockingElements.filter((pb) => getPathLength(getTrackSubPath(track, pb.path[pb.path.length - 1], track.data.path[0])) <= getPathLength(getTrackSubPath(track, point, track.data.path[0]))),
        closingSide: pathsToBlockingElements.filter((pb) => getPathLength(getTrackSubPath(track, pb.path[pb.path.length - 1], track.data.path[track.data.path.length - 1])) < getPathLength(getTrackSubPath(track, point, track.data.path[track.data.path.length - 1])))
    };

    let pathToStartEdges = [track.data.path[0], point];
    let pathToEndEdges = [point, track.data.path[track.data.path.length - 1]];

    // On prend le path et on recherche un subpath qui va jusqu'au premier aiguillage à ne pas être bloquant (= pas vert)
    // Si aucun aiguillage n'est trouvé alors on va jusqu'au bout du path
    // On souhaite aussi éviter les chemins qui font du surplace (voir le if)
    if(pathToStartEdges[0].x != pathToStartEdges[1].x || pathToStartEdges[0].y != pathToStartEdges[1].y) {
        var firstFreeSubPathStartingSide = trackPathToElements.startingSide.reduce(
            (prev, cur) => (cur.path.length == prev.path.length ? getPathLength(cur.path) < getPathLength(prev.path) : cur.path.length < prev.path.length) ? cur : prev,
            { path: getTrackSubPath(track, pathToStartEdges[0], pathToStartEdges[1]) }
        )
        result.push(...exploreRoutesOnPath(point, firstFreeSubPathStartingSide.path, synoptique, track, firstFreeSubPathStartingSide.target, forbiddenOrigins));
    }
    if(pathToEndEdges[0].x != pathToEndEdges[1].x || pathToEndEdges[0].y != pathToEndEdges[1].y) {
        var firstFreeSubPathClosingSide = trackPathToElements.closingSide.reduce(
            (prev, cur) => (cur.path.length == prev.path.length ? getPathLength(cur.path) < getPathLength(prev.path) : cur.path.length < prev.path.length) ? cur : prev,
            { path: getTrackSubPath(track, pathToEndEdges[0], pathToEndEdges[1]) }
        );
        result.push(...exploreRoutesOnPath(point, firstFreeSubPathClosingSide.path, synoptique, track, firstFreeSubPathClosingSide.target, forbiddenOrigins));
    }
    return result;
}

function saveSynoptique(synoptique) {
    let node = {
        url: synoptique.id ? '/synoptique/' + synoptique.id + '/edit' : '/synoptique/new',
        data: {
            synoptique_form: {
                title: synoptique.title ?? 'synoptique',
                clientId: synoptique.clientId,
                notes: synoptique.notes.map((n) => {
                    return {
                        content: n.data.content,
                        backgroundColor: n.data.backgroundColor,
                        backgroundOpacity: n.data.backgroundOpacity,
                        fontColor: n.data.fontColor,
                        xPosition: n.data.position.x,
                        yPosition: n.data.position.y,
                        fontSize: n.data.fontSize
                    }
                }),
                tips: synoptique.tips.map((tip) => {
                    return {
                        label: tip.data.label,
                        xPosition: tip.data.position.x,
                        yPosition: tip.data.position.y,
                        departurePoints: tip.data.departurePoints.map(tipPoint => {
                            return { xPosition: tipPoint.position.x, yPosition: tipPoint.position.y, label: tipPoint.label ?? '' }
                         }),
                        arrivalPoints: tip.data.arrivalPoints.map(tipPoint => {
                            return { xPosition: tipPoint.position.x, yPosition: tipPoint.position.y, label: tipPoint.label ?? '' }
                        }),
                    }
                })
            },
        }
    };

    if(synoptique.public) node.data.synoptique_form.public = 1;
    return $.post(node).then((res) => synoptique.id = res.id);
}

function saveTracks(synoptique) {
    return Promise.all(synoptique.tracks.map((t) => {
        let node = {
            number: t.data.number,
            path: t.data.path.map((p) => { return { x: p.x, y: p.y } }),
            xNumberPosition: t.data.xNumberPosition ?? 0,
            yNumberPosition: t.data.yNumberPosition ?? 0,
            derailers: t.derailers.map((d) => {
                return {
                    xPosition: d.data.position.x,
                    yPosition: d.data.position.y,
                    sensorId: d.data.sensorId
                }
            }),
            instrumentalizedSections: t.instrumentalizedSections.map((s) => {
                let subNode = {
                    xPositionStart: s.data.positionStart.x,
                    yPositionStart: s.data.positionStart.y,
                    xPositionEnd: s.data.positionEnd.x,
                    yPositionEnd: s.data.positionEnd.y,
                    sensorStartId: s.data.sensorStartId,
                    sensorEndId: s.data.sensorEndId
                };
                if(s.data.dualSensor) subNode.dualSensor = 1;
                return subNode;
            }),
            constructionSections: t.constructionSections?.map((s) => {
                return {
                    xPositionStart: s.data.positionStart.x,
                    yPositionStart: s.data.positionStart.y,
                    xPositionEnd: s.data.positionEnd.x,
                    yPositionEnd: s.data.positionEnd.y,
                };
            }) ?? []
        }
        
        if(t.startingEdge) node.startingEdge = {
            label: t.startingEdge.data.label,
            xLabelPosition: parseInt(t.startingEdge.data.labelPosition.x ?? 0),
            yLabelPosition: parseInt(t.startingEdge.data.labelPosition.y ?? 0),
        };
        if(t.closingEdge) node.closingEdge = {
            label: t.closingEdge.data.label,
            xLabelPosition: parseInt(t.closingEdge.data.labelPosition.x ?? 0),
            yLabelPosition: parseInt(t.closingEdge.data.labelPosition.y ?? 0),
        };
        if(t.data.routeFromStartEnabled) node.routeFromStartEnabled = 1;
        if(t.data.routeFromEndEnabled) node.routeFromEndEnabled = 1;

        return $.post({
            url: '/synoptique/' + synoptique.id + '/track' + (t.id > 0 ? '/' + t.id : ''),
            data: {
                track_form: node
            }
        }).then((res) => {
            t.id = res.id;
            if (t.startingEdge) t.startingEdge.id = res.startingEdge?.id;
            if (t.closingEdge) t.closingEdge.id = res.closingEdge?.id;
            t.derailers.forEach((d, i) => d.id = res?.derailers[i].id);
            t.instrumentalizedSections.forEach((d, i) => d.id = res?.instrumentalizedSections[i].id);
            t.constructionSections.forEach((d, i) => d.id = res?.constructionSections[i].id);
        });
    }));
}

function saveSwitches(synoptique) {
    return Promise.all(synoptique.switches.map((swt) => {
        return $.post({
            url: '/synoptique/' + synoptique.id + '/switch' + (swt.id > 0 ? '/' + swt.id : ''),
            data: {
                track_switch_form: {
                    number: swt.data.number,
                    xIntersection: swt.data.intersection.x,
                    yIntersection: swt.data.intersection.y,
                    branchingTracks: swt.data.branchingTracks?.map((branch, i) => {
                        return {
                            xPosition: branch.position.x,
                            yPosition: branch.position.y,
                            sensorId: i == 0 ? swt.data.sensorAId : i == 1 ? swt.data.sensorBId : null,
                            track: branch.track?.id ?? branch.trackId
                        };
                    })
                }
            }
        }).then((res) => swt.id = res.id);
    }));
}


function deleteItems(itemsToDelete) {
    return Promise.all(Object.entries(itemsToDelete).map((entryToDelete) => Promise.all(entryToDelete[1].map(
        (elem) => $.ajax({
            method: 'DELETE',
            url: '/synoptique/' + entryToDelete[0] + '/' + elem
        })
    )).then(() => itemsToDelete[entryToDelete[0]] = [])));
}

async function saveAll(synoptique, itemsToDelete) {
    await deleteItems(itemsToDelete);
    await saveSynoptique(synoptique);
    await saveTracks(synoptique);
    // Switches must be saved after the eventual new tracks
    await saveSwitches(synoptique);
}

export { parseSynoptique, parseSynoptiqueSensors, drawSynoptique, computeSynoptiqueOpenRoutes, computeSynoptiqueTipRoutes, computeTipConnections, saveSynoptique, saveTracks, saveSwitches, deleteItems, saveAll }